<template>
   <div class="mt-12" style="height: calc(100vh - 344px);">
      <base-info-card title="SIGNIN FORM" subtitle="Let us know your authorization" color="primary" />
      <v-form ref="form" @submit.prevent="signin">
         <v-text-field v-model="form.email" label="Credential" outlined required :rules="[v => !!v || 'Credential is required']" />
         <v-text-field v-model="form.password" label="Password" outlined required :rules="[v => !!v || 'Password not blank']"
            :type="seen?'text':'password'" :append-icon="seen?'mdi-eye':'mdi-eye-off'" @click:append="seen=!seen"
            />
         <v-btn x-large color="accent" depressed type="submit">
            Submit
         </v-btn>
      </v-form>
   </div>
</template>

<script>
import { encrypt } from '@/utils/nirtara'
import { member } from '@/utils/fields.js'
export default {
   name: 'FormLogin',

   data: () => ({
      seen: false,
      form: Object.assign({}, member),
   }),

   created () {
      process.env.NODE_ENV === 'development' && Object.assign(this.form, { email: 'nvm@50.web', password: 'nvm50web' })
   },
   methods: {
      signin: function () {
         if (!this.$refs.form.validate()) return

         const password = encrypt(this.form.password)
         this.$store.dispatch('signin', { kind: this.form.kind, credential: this.form.email, password })
         .then(result => {
            result.Route && this.$router.push(result)
            result.Message && this.$store.commit('snackNotification', result)
         })
         .catch(error => console.log(error))
      },
   },
}
</script>
